




































































































































































































































































































import Vue from 'vue';
import projectForm from './project-form.vue';
import * as api from '@/api/project';
import moment from 'moment';
import { agencies_benefitBaseInfo } from '@/api';
import { mapGetters } from 'vuex';
import { coccSpsServer_1_construct_status_mergeRecordNow } from '@/api/projectDetail';
import ExportList from '@/views/projects/detail/exportTender/list.vue';
import UpgradeInfo from './upgradeInfo.vue';
import { updateVersions } from '@/api/project';
interface resData {
  [key: string]: any;
}
export default Vue.extend({
  name: 'project',
  components: {
    ExportList,
    projectForm,
    UpgradeInfo,
  },
  data() {
    return {
      action: 'add',
      detailsId: '',
      constructId: '', // 指定项目constructId
      showStatus: false,
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20, // 每页显示多少条
        pageSizeOptions: ['20', '40', '60', '80', '100'],
      },
      keyword: '',
      tableData: [],
      optionsShow: false, // 操作是否展示
      projectStatus: '', // 项目状态
      platformSurplus: '', // 所属企业工程项目创建剩余权益
      currentTime: 0,
      start: false, // 是否停止轮询
      timer: 0,
      currentCombinedList: [], // 当前数据列表中正在组价的数据
      defaultImg:
        'https://hzjt-ui-publiclib.oss-cn-beijing.aliyuncs.com/YZJXPT/bgimage/image1.png',
      exportVisible: false,
      upgradeVisible: false,
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  mounted() {
    this.updateVersions();
    this.getList();
    this.getUserCountCompanyData();
  },
  methods: {
    getUserCountCompanyData() {
      agencies_benefitBaseInfo().then((res: any) => {
        this.platformSurplus = res.result.platformSurplus;
      });
    },
    details(item: any) {
      if (item.auditStatus === 1 || item.projectSpaceStatus === 1) {
        this.$store.commit('SET_PROJECTSEQUENCENBR', item.constructId);
        this.$router.push({
          path: '/ysf/project/detail',
          query: {
            sequenceNbr: item.constructId,
            spaceId: item.sequenceNbr,
            projectSource: item.projectSource,
          },
        });
      } else {
        this.$message.error(
          `该项目 ${
            item.auditStatus === 0
              ? '创建审核中'
              : item.auditStatus === 8
              ? '已作废'
              : '已驳回'
          } , 暂无法查看。`
        );
      }
    },
    heandDate(val: Date) {
      return moment(val).format('YYYY-MM-DD');
    },
    refresh() {
      if (this.action === 'add') {
        this.page.currentPage = 1;
      }
      this.getList();
    },
    showForm(type = 'add', obj) {
      console.log('obj', obj);
      this.getUserCountCompanyData();
      this.action = type;
      this.detailsId = obj ? obj.sequenceNbr : '';
      this.constructId = obj ? obj.constructId : '';
      if (type === 'isTop') {
        this.updateTop(obj);
      } else {
        this.showStatus = true;
      }
    },
    exportList() {
      this.exportVisible = true;
    },
    change(page: number) {
      this.page.currentPage = page;
      this.getList();
    },
    showSizeChange(current: number, size: number) {
      this.page.currentPage = 1;
      this.page.pageSize = size;
      this.getList();
    },
    getSearch() {
      this.page.currentPage = 1;
      this.getList();
    },
    getList() {
      api
        .coccMtdsResource_1_projectSpace_constructs({
          page: this.page.currentPage,
          limit: this.page.pageSize,
          projectSpaceName: this.keyword,
          projectSpaceStatus: this.projectStatus === '3' ? 1 : '',
          auditStatus: this.projectStatus === '3' ? '' : this.projectStatus,
        })
        .then((res: resData) => {
          let { result } = res;
          this.currentTime = Date.now();
          this.start = true;
          result.list.forEach((item) => {
            item.imageType = item.coverUrl?.substring(
              item.coverUrl.lastIndexOf('/') + 1,
              item.coverUrl.length
            );
            item.optionsShow = false;
          });
          this.tableData = result.list;
          this.page.total = result.total;
          this.currentCombinedList = this.tableData.filter(
            (x: any) => x.status === 1
          );
          if (this.start) {
            this.setIntervalInfo();
          }
        });
    },
    updateTop(obj) {
      const postData = {
        projectSpaceName: obj.projectSpaceName,
        ssProvince: obj.ssProvince,
        ssCity: obj.ssCity,
        coverUrl: obj.coverUrl,
        ssProvinceName: obj.ssProvinceName,
        ssCityName: obj.ssCityName,
        isTop: obj.isTop === 0 ? 1 : 0,
        sequenceNbr: obj.sequenceNbr,
      };
      api.coccMtdsResource_1_constructProjectSpace_put(postData).then((res) => {
        console.log('res', res);
        if (res.status === 200) {
          this.$message.success(`${obj.isTop === 0 ? '取消置顶' : '置顶'}成功`);
          this.getList();
        }
      });
    },
    selectChange() {
      this.page.currentPage = 1;
      this.getList();
    },
    // 获取所有正在组价中的状态
    mergeRecordNow() {
      let number = 0;
      coccSpsServer_1_construct_status_mergeRecordNow().then((res) => {
        this.tableData.forEach((item: any) => {
          if (Object.keys(res.result).includes(item.constructId)) {
            number++;
          }
        });
        if (this.currentCombinedList.length !== number) {
          this.getList();
        }
      });
    },
    // 轮询正在组价状态接口
    setIntervalInfo() {
      if (Date.now() - this.currentTime > 3000) {
        this.currentTime = Date.now();
        this.mergeRecordNow();
      }
      if (this.start) {
        this.timer = requestAnimationFrame(this.setIntervalInfo);
      }
    },
    updateVersions() {
      updateVersions().then((res) => {
        if (res.status === 200 && !res.result) {
          this.upgradeVisible = true;
        }
      });
    },
  },
  destroyed() {
    this.start = false;
    this.timer && cancelAnimationFrame(this.timer);
  },
});
